.popup__wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000aa;
    z-index: 100;
}

.popup__wrapper.active {
    display: block;
}

.popup__content {
    position: absolute;
    width: 975px;
    height: 730px;
    top: 30px;
    left: 50%;
    transform: translate(-50%);
    background: #292B45;
    border-radius: 10px;
    box-shadow: 0px 0px 150px 0px #333445;
    overflow: hidden;
}

.popup__head {
    width: 975px;
    height: 70px;
    box-sizing: border-box;
    padding: 0 30px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #242538;
}

.popup__head h2 {
    font-size: 24px;
}

.popup__head button {
    background-color: transparent;
    box-shadow: none !important;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.popup__head button:hover {
    background-color: #253861;
}

.popup__body {
    display: flex;
    gap: 15px;
    height: 670px;
    box-sizing: border-box;
}

.popup__categories {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 30%;
    padding-top: 25px;
    padding-left: 30px;
}

.popup__courses {
    height: 100%;
    padding-top: 25px;
    padding-right: 30px;
    box-sizing: border-box;
    flex-grow: 2;
    flex-shrink: 0;
    flex-basis: 60%;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #191A28;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #6F78C8;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
}

.popup__course__title {
    padding: 10px;
    display: flex;
    align-items: center;
}

.popup__course__name {
    margin-left: 45px;
}

.popup__course__age {
    width: 90px;
    height: 35px;
    text-align: center;
    border-radius: 8px;
    background: #E3F6FF;
    box-sizing: border-box;
    line-height: 35px;
    color: #6979B8;
    font-size: 14px;
}

.popup__category {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    text-align: left;
    background-color: transparent;
    padding: 12px 17px 12px 20px;
    border-radius: 5px;
    color: #6979B8;
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
}

.popup__category:hover {
    box-shadow:none;
    background: #3F4281;
    color:  #FFF;

}

.popup__category:active,
.popup__category.active {
    color:  #FFF;
    background: var(--unnamed, #8588C8);
    box-shadow: 0px 0px 6px 0px rgba(133, 136, 200, 0.80);
}


/* .popup__category.active {
    background: #242538;
    color: #fff;
} */

.popup__course {
    border-radius: 5px;
    height: 160px;
    margin-bottom: 15px;
    background-color: #fff;
    padding: 4px 4px 4px 15px;
    display: flex;
    justify-content: space-between;
}

.popup__course__spec {
    padding: 20px 0;
}

.popup__course__spec li::before {
    content: url('../../img/course_star.svg');
    margin-right: 16px;
}

.popup__course__spec li {
    margin-bottom: 10px;
}

.popup__course__description {
    width: 300px;
}

.popup__course__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    border-radius: 5px;
    background: #6F78C8;
}



@media screen and (max-width: 425px) {
    .popup__wrapper {
        width: 100%;
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        background: transparent;
    }

    .popup__content {
        width: 100%;
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        transform: none;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
    }

    .popup__head {
        display: none;
    }

    .popup__body {
        flex-direction: column;
        height: 500px;
    }

    .popup__categories {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 0;
        flex: 0 0 0;
    }

    .popup__categories button {
        padding: 6px 10px;
        background-color: var(--text-blue);
        color: var(--white);
        font-weight: 400;
        transition: 0.3s;
        font-size: 12px;
        width: auto;
        margin: 0;
        height: 28px;
    }

    .popup__categories button:hover,
    .popup__categories button.active {
        background-color: var(--light-blue);
        box-shadow: none;
        filter: drop-shadow(0px 0px 6px rgba(133, 136, 200, 0.80));
    }

    .popup__courses {
        padding: 0;
    }

    .popup__course {
        height: 75px;
    }

    .popup__course__title {
        padding: 0;
        height: 100%;
        gap: 10px;
    }

    .popup__course__age {
        width: 90px;
        font-size: 14px;
        flex-shrink: 0;
    }

    .popup__course__name {
        font-size: 16px;
        margin: 0;
    }

    .popup__course__apps {
        display: none;
    }

    .popup__course__spec {
        display: none;
    }
}